import React from "react";
import { graphql } from "gatsby";

// components
import { SortedCards, Seo, CollectionsLinks } from "../../components";
import Layout from "../../components/Layout";

// hooks
import { useScrollToTop } from "../../hooks/useScrollToTop";

const Sale = ({ data }) => {
  useScrollToTop(data);

  return (
    <Layout>
      <Seo title="City Diaries" />
      <CollectionsLinks />
      <SortedCards books={data.sale.nodes} title="Sale" />
    </Layout>
  );
};

export const query = graphql`
  query GetSales {
    sale: allAirtable(
      filter: {
        data: { categories: { eq: "Sale" }, status: { ne: "Sold-Out" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default Sale;
